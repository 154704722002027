@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

* {
  /*font-family: "Inter", sans-serif;*/
  /*font-family: "Lato", sans-serif;*/
  /*font-family: "Open Sans", sans-serif;*/
  font-family: "Nunito", sans-serif;
  /*font-family: "Montserrat", sans-serif;*/
  /*font-family: "Raleway", sans-serif;*/
  scroll-behavior: smooth;
}

.container {
  width: 100%;
  max-width: 1230px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.slicing {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bg-specwhite {
  background-color: rgba(255, 255, 255, 0.62);
}
